<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-row
      class="mt-3"
    >
      <b-col
        cols="12"
      >
        <b-card>
          <ValidationObserver
            ref="formChangePassword"
          >
            <form
              novalidate
              role="form"
              @submit.prevent
            >
              <b-row>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    vid="current_password"
                    name="current password"
                    rules="required"
                  >
                    <b-form-group
                      label="Current Password"
                      label-for="current_password"
                      label-class="font-weight-bold"
                      class="mb-2"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input
                          id="current_password"
                          v-model="credentials.current_password"
                          placeholder="enter current password"
                          autocomplete="off"
                          :type="currentPasswordType"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <b-input-group-append>
                          <b-button
                            class="pl-2 pr-1 text-dark input-icon"
                            variant="warning"
                            :disabled="state.busy"
                            @click.prevent="toggleCurrentPassword"
                          >
                            <i
                              v-if="state.current_password_visible"
                              class="ri-eye-line h5"
                            />
                            <i
                              v-else
                              class="ri-eye-off-line h5"
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    vid="password"
                    name="new password"
                    :rules="{
                      required: true,
                      not_equal: 'current_password'
                    }"
                  >
                    <b-form-group
                      label="New Password"
                      label-for="password"
                      label-class="font-weight-bold"
                      class="mb-2"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input
                          id="password"
                          v-model="credentials.password"
                          placeholder="enter new password"
                          autocomplete="off"
                          :type="newPasswordType"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <b-input-group-append>
                          <b-button
                            class="pl-2 pr-1 text-dark input-icon"
                            variant="warning"
                            :disabled="state.busy"
                            @click.prevent="toggleNewPassword"
                          >
                            <i
                              v-if="state.new_password_visible"
                              class="ri-eye-line h5"
                            />
                            <i
                              v-else
                              class="ri-eye-off-line h5"
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="confirm password"
                    vid="password_confirmation"
                    rules="required|min:8|confirmed:password"
                  >
                    <b-form-group
                      label="Confirm New Password"
                      label-for="password_confirmation"
                      label-class="font-weight-bold"
                      class="mb-2"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input
                          id="password_confirmation"
                          v-model="credentials.password_confirmation"
                          placeholder="confirm new password"
                          autocomplete="off"
                          :type="newPasswordType"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <b-input-group-append>
                          <b-button
                            class="pl-2 pr-1 text-dark input-icon"
                            variant="warning"
                            :disabled="state.busy"
                            @click.prevent="toggleNewPassword"
                          >
                            <i
                              v-if="state.new_password_visible"
                              class="ri-eye-line h5"
                            />
                            <i
                              v-else
                              class="ri-eye-off-line h5"
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>

              <div class="d-flex justify-content-end w-100">
                <b-button
                  variant="success"
                  :disabled="state.busy"
                  @click.prevent="onValidateChangePassword"
                >
                  Update Password
                </b-button>
              </div>
            </form>
          </ValidationObserver>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { AxiosError, SSharedAuth } from '@/services'

export default {
  name: 'VSharedChangePassword',

  middleware: ['auth'],

  metaInfo () {
    return {
      title: 'Change Password'
    }
  },

  data () {
    return {
      state: {
        busy: false,
        current_password_visible: false,
        new_password_visible: false
      },
      credentials: {
        current_password: '',
        password: '',
        password_confirmation: ''
      }
    }
  },

  computed: {

    currentPasswordType () {
      return this.state.current_password_visible ? 'text' : 'password'
    },

    newPasswordType () {
      return this.state.new_password_visible ? 'text' : 'password'
    }

  },

  mounted () {
    core.index()
  },

  methods: {

    toggleCurrentPassword () {
      this.state.current_password_visible = !this.state.current_password_visible
    },

    toggleNewPassword () {
      this.state.new_password_visible = !this.state.new_password_visible
    },

    async onValidateChangePassword () {
      await this.$refs.formChangePassword.validate().then(
        async areAllFieldsValid => {
          if (areAllFieldsValid) {
            this.$swal.fire({
              icon: 'question',
              title: 'Confirmation',
              text: 'Change your Password?',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Confirm',
              cancelButtonColor: '#737373',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: () => {
                return this.onPost()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          }
        }
      )
    },

    async onPost () {
      this.state.busy = true
      return new Promise(
        resolve => {
          SSharedAuth.changePassword(this.credentials).then(
            ({ data }) => {
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                this.credentials.current_password = ''
                this.credentials.password = ''
                this.credentials.password_confirmation = ''
                this.$refs.formChangePassword.reset()
              })
            }
          ).catch(
            error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.formChangePassword.setErrors(error.message)
                }
              }
            }
          ).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    }
  }

}
</script>
